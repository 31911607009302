:root {
  --font: "Nunito", sans-serif;
  --game-width: 970px;

  --light-background: #ffffff;
  --light-backdrop: #e4e4e4;
  --light-text: #000000;
  --dark-backdrop: #242424;
  --dark-background: #121213;
  --dark-text: #ffffff;
}

.main-container {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  font-family: var(--font);
}

.app-container {
  display: flex;
  flex-direction: column;
  width: var(--game-width);
  overflow: hidden;
}

.game-container {
  height: 100%;
  overflow: hidden;
  transition: var(--theme-transition);
  max-width: var(--game-width);
  width: 100%;
}

/* Accessibility styling */
.outline:focus-visible {
  outline: 0.15rem solid #234bcf;
}

/* Menu and board component fade animations */
.fade-out-left {
  animation: fade-out-left 0.3s ease-in-out;
}
.fade-out-right {
  animation: fade-out-right 0.3s ease-in-out;
}
.fade-in-left {
  animation: fade-in-left 0.3s ease-in-out;
}
.fade-in-right {
  animation: fade-in-right 0.3s ease-in-out;
}
.display-none {
  display: none !important;
}
@keyframes fade-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-10rem);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(10rem);
    opacity: 0;
  }
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-10rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    transform: translateX(10rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
