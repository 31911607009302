:root {
  --font: "Nunito", sans-serif;
  --game-width: 970px;
  --transition: 300ms background-color, 300ms color, 300ms border;

  --light-gray: #e1e2e3;
  --dark-gray: #b6b6b6;
  --green: #6fb05c;
  --yellow: #cfaa09;
  --red: red;

  --light-background: #ffffff;
  --light-text: #000000;
  --light-tile-color: #e1e2e3;
  --dark-background: #121213;
  --dark-backdrop: #242424;
  --dark-text: #ffffff;
  --dark-tile-color: #3a3a3c;

  --dark-button-background-hover: #2e2e30;
  --light-button-background-hover: #d8d8d8;
}

/* Generic modal css */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: var(--game-width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  font-family: var(--font);
  background-color: #fff;
  width: 100%;
  max-height: 95%;
  max-width: var(--game-width);
  padding: 1rem 0rem 2rem 0rem;
  overflow-y: auto;
  animation: fade-in-bottom 0.2s ease-out;
}

.modal-fade-out {
  animation: fade-out-bottom 0.2s ease-in;
}

.modal-title-container {
  display: flex;
  margin: 0.5rem 0rem;
  justify-content: flex-start;
}

.modal-close-button {
  all: unset;
  cursor: pointer;
  margin: 0.2rem 1rem 0rem 0rem;
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

/* Modal Content */

.modal-title {
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
  margin-left: 2.3rem;
}

.modal-subtitle {
  text-align: center;
  font-weight: 700;
}

.modal-line {
  border-width: 1px 0 0;
}

/* Instructions */
.modal-point-description-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0rem 2rem;
  gap: 0.5rem;
}
.instructions-tutorial-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.tile-tutorial {
  transition: var(--transition);
}
.background-fill-light {
  background-color: var(--light-tile-color) !important;
}
.background-fill-dark {
  background-color: var(--dark-tile-color) !important;
}
.color-light {
  color: var(--light-text) !important;
}
.color-dark {
  color: var(--dark-text) !important;
}
.cursor-unset {
  cursor: unset !important;
}
.margin-unset {
  margin: unset !important;
}
.text-align-center {
  text-align: center;
}
.small-gap {
  gap: 0.5rem !important;
}
.tile-tutorial-filled {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  font-weight: 700;
  color: #ffffff;
  transition: var(--transition);
  background-color: var(--green);
}

.tile-tutorial {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  font-weight: 700;
  border: 0.15rem solid var(--light-gray);
  transition: var(--transition);
}

.tile-border-light {
  border: 0.15rem solid var(--light-tile-color);
}
.tile-border-dark {
  border: 0.15rem solid var(--dark-tile-color);
}
.instructions-table {
  text-align: center;
}
.instructions-table-container {
  display: flex;
  justify-content: center;
}

/* Statistics */
.statistics-table {
  text-align: center;
}

.statistics-todays-results-container {
  display: flex;
  justify-content: center;
}

.statistics-hr {
  width: 90%;
  border-width: 1px 0 0;
}

.statistics-bar-chart-container {
  display: flex;
  justify-content: center;
}
.responsive-bar-chart-container {
  position: relative;
  overflow: hidden;
}

.responsive-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.statistics-plot {
  width: 100%;
  height: 100%;
}

.statistics-plot-dark > div > div > svg:first-of-type {
  background: var(--dark-background) !important;
}

.statistics-plot-light > div > div > svg:first-of-type {
  background: var(--light-background) !important;
}
/* Settings */

.settings-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--dark-gray);
}
.border-bottom-none {
  border-bottom: none !important;
}
.feedback-link {
  all: unset;
  cursor: pointer;
  color: #818181;
  font-weight: 700;
}
.switch {
  position: relative;
  display: inline-block;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-gray);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: var(--green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--green);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.settings-username-input {
  all: unset;
  text-align: right;
  font-weight: 700;
}

/* Support */
.support-link-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
}

.support-link {
  all: unset;
  cursor: pointer;
  font-weight: 700;
  border-radius: 0.5rem;
}

.support-link-dark:hover {
  background-color: var(--dark-button-background-hover) !important;
}
.support-link-light:hover {
  background-color: var(--light-button-background-hover) !important;
}

/* Intro Modal */
.bonus-letter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

/* Game Over Modal */
.game-over-modal-bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 1rem;
}
.game-over-countdown-container {
  text-align: center;
  font-size: 1.1rem;
}
.share-button {
  all: unset;
  cursor: pointer;
  font-weight: 700;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.share-svg {
  position: relative;
  top: 2px;
}
.share-button-dark:hover {
  background-color: var(--dark-button-background-hover) !important;
}
.share-button-light:hover {
  background-color: var(--light-button-background-hover) !important;
}
.copy-to-clipboard-popup {
  display: flex;
  position: fixed;
  transform: translateX(-50%);
  border-radius: 0.3rem;
  font-weight: 700;
  animation: popup-fade-in 0.3s;
  font-size: 1.25rem;
  padding: 0.5rem;
  white-space: nowrap;
  top: 5rem;
  left: 50%;
  justify-content: center;
  z-index: 10000;
}

.popup-fade-out {
  animation: popup-fade-out 0.3s;
}
@keyframes popup-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popup-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Leaderboard Modal */
.leaderboard-select-container {
  margin: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.leaderboard-select-dark {
  background-color: var(--dark-tile-color);
  color: var(--dark-text);
}

.leaderboard-select-light {
  background-color: var(--light-tile-color);
  color: var(--light-text);
}

.leaderboard-select {
  cursor: pointer;
  margin: 0rem;
  border: none;
  text-align: center;
  border-radius: 0.3rem;
  font-weight: 700;
}

.leaderboard-table-container {
  display: flex;
  justify-content: center;
}

.leaderboard-table {
  text-align: center;
  width: 95%;
}

/* Media Queries for responsiveness */

/* X-Small */
@media screen and (max-width: 320px) {
  .modal-title {
    font-size: 1.8rem !important;
  }
  .modal-subtitle {
    font-size: 1.25rem !important;
  }
  .modal-text {
    font-size: 0.9rem !important;
    margin: 0.6rem 1.1rem !important;
  }
  .modal-line {
    margin: 1.5rem 1.1rem !important;
  }
  .tile-tutorial {
    height: 2rem !important;
    width: 2rem !important;
    font-size: 1.5rem !important;
  }
  .tile-tutorial-filled {
    height: 1.7rem !important;
    width: 1.7rem !important;
    font-size: 1.1rem !important;
  }
  .settings-container {
    margin: 0rem 1rem !important;
    padding: 0.75rem 0rem !important;
  }
  .settings-text {
    font-size: 0.9rem !important;
  }
  .settings-username-input {
    width: 8.2rem !important;
    font-size: 0.9rem !important;
  }
  .switch {
    width: 2.5rem !important;
    height: 1.5rem !important;
  }
  .slider::before {
    height: 1.05rem !important;
    width: 1.05rem !important;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(1rem) !important;
    -ms-transform: translateX(1rem) !important;
    transform: translateX(1rem) !important;
  }
  .support-link {
    font-size: 0.95rem !important;
    padding: 0.7rem !important;
  }
  .feedback-link {
    font-size: 0.95rem !important;
  }
  .statistics-table {
    font-size: 1rem !important;
  }
  .statistics-table-cell {
    padding: 0.25rem 1rem !important;
  }
  .instructions-table {
    font-size: 0.9rem !important;
    width: 95% !important;
  }
  .responsive-bar-chart-container {
    width: 100% !important;
    height: 16rem !important;
  }
  .leaderboard-select {
    font-size: 0.95rem !important;
    height: 2.25rem !important;
    width: 6rem !important;
  }
  .leaderboard-table {
    font-size: 0.85rem !important;
  }
  .leaderboard-table-cell {
    padding: 0.15rem 0.75rem !important;
  }
}

/* Small */
@media screen and (max-width: 600px) {
  .modal-title {
    font-size: 2rem;
  }
  .modal-subtitle {
    font-size: 1.5rem;
  }
  .modal-text {
    font-size: 1rem;
    margin: 0.75rem 1.25rem;
  }
  .modal-line {
    margin: 2rem 1.25rem;
  }
  .tile-tutorial {
    height: 2.3rem;
    width: 2.3rem;
    font-size: 1.8rem;
  }
  .tile-tutorial-filled {
    height: 2rem;
    width: 2rem;
    font-size: 1.3rem;
  }
  .settings-container {
    margin: 0rem 2rem;
    padding: 0.75rem 0rem;
  }
  .settings-text {
    font-size: 1rem;
  }
  .settings-username-input {
    width: 9.5rem;
    font-size: 1rem;
  }
  .switch {
    width: 2.75rem;
    height: 1.65rem;
  }
  .slider::before {
    height: 1.2rem;
    width: 1.2rem;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(1.05rem);
    -ms-transform: translateX(1.05rem);
    transform: translateX(1.05rem);
  }
  .support-link {
    font-size: 1rem;
    padding: 0.75rem;
  }
  .feedback-link {
    font-size: 1rem;
  }
  .statistics-table {
    font-size: 1.1rem;
  }
  .statistics-table-cell {
    padding: 0.25rem 1.5rem;
  }
  .instructions-table {
    font-size: 0.95rem;
    width: 90%;
  }
  .responsive-bar-chart-container {
    width: 95%;
    height: 20rem;
  }
  .leaderboard-select {
    font-size: 1rem;
    height: 2.5rem;
    width: 7rem;
  }
  .leaderboard-table {
    font-size: 1rem;
  }
  .leaderboard-table-cell {
    padding: 0.15rem 1.1rem;
  }
}

/* Large */

@media screen and (min-width: 600px) {
  .modal-title {
    font-size: 2.5rem;
  }
  .modal-subtitle {
    font-size: 2rem;
  }
  .modal-text {
    font-size: 1.25rem;
    margin: 1rem 2.5rem;
  }
  .modal-line {
    margin: 2.5rem 2.5rem;
  }
  .tile-tutorial {
    height: 3rem;
    width: 3rem;
    font-size: 2.2rem;
  }
  .tile-tutorial-filled {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1.8rem;
  }
  .settings-container {
    margin: 0rem 4rem;
    padding: 1rem 0.5rem;
  }
  .settings-text {
    font-size: 1.25rem;
  }
  .settings-username-input {
    width: 11.5rem;
    font-size: 1.25rem;
  }
  .switch {
    width: 3.75rem;
    height: 2.1rem;
  }
  .slider::before {
    height: 1.6rem;
    width: 1.6rem;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(1.6rem);
    -ms-transform: translateX(1.6rem);
    transform: translateX(1.6rem);
  }
  .support-link {
    font-size: 1.5rem;
    padding: 1rem;
  }
  .feedback-link {
    font-size: 1.25rem;
  }
  .statistics-table {
    font-size: 1.25rem;
  }
  .statistics-table-cell {
    padding: 0.25rem 2rem;
  }
  .instructions-table {
    font-size: 1.25rem;
    width: 75%;
  }
  .responsive-bar-chart-container {
    width: 75%;
    height: 30rem;
  }
  .leaderboard-select {
    font-size: 1.25rem;
    height: 3rem;
    width: 10rem;
  }
  .leaderboard-table {
    font-size: 1.5rem;
  }
  .leaderboard-table-cell {
    padding: 0.15rem 2.25rem;
  }
}
