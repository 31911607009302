:root {
  --light-border-color: #000000;
  --dark-border-color: #ffffff;
}

.title-container {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
}

.title-tile-l,
.title-tile-s {
  display: inline-flex;
  padding: 0.5rem;
  margin: 0.2rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.3rem;
  font-size: 2.5rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.title-right-container {
  margin-top: 1rem;
}
