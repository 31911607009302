:root {
  --font: "Nunito", sans-serif;
  --transition: 300ms background-color, 300ms color, 300ms border;

  --light-gray-1: #e1e2e3;
  --light-gray-2: #cccccc;

  --light-button-background: #e1e2e3;
  --light-button-background-hover: #dfdfdf;
  --dark-button-background: #3a3a3c;
  --dark-button-background-hover: #2e2e30;
  --light-game-menu-button-background-hover: #d8d8d8;
}

.menu-container {
  font-family: var(--font);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
}

.title-subtext {
  font-size: 1.5rem;
  text-align: center;
  max-width: 16rem;
  padding: 1rem;
}

.menu-button {
  all: unset;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  width: 10rem;
  text-align: center;
}

.menu-svg-button {
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.menu-button-dark,
.menu-svg-button-dark {
  background-color: var(--dark-button-background);
}

.menu-button-light,
.menu-svg-button-light {
  background-color: var(--light-button-background);
}

.menu-button-dark:hover,
.menu-svg-button-dark:hover {
  background-color: var(--dark-button-background-hover);
}

.menu-button-light:hover,
.menu-svg-button-light:hover {
  background-color: var(--light-button-background-hover);
}

.menu-bottom-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 1.5rem 0rem;
}

.game-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.game-menu-button-container {
  display: flex;
  cursor: pointer;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
}

.game-menu-background-light:hover {
  background-color: var(--light-game-menu-button-background-hover);
}
.game-menu-background-dark:hover {
  background-color: var(--dark-button-background-hover);
}

.game-menu-text {
  font-size: 1.25rem;
  font-weight: 700;
}

.game-menu-line {
  width: 85%;
  margin: 0rem 1.5rem;
}

.game-menu-socials-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
}

.game-menu-socials-link {
  all: unset;
  cursor: pointer;
}
