:root {
  --font: "Nunito", sans-serif;
  --game-width: 970px;
  --transition: 300ms background-color, 300ms color, 300ms border;

  --light-gray: #e1e2e3;
  --dark-gray: #b6b6b6;
  --green: #6fb05c;
  --yellow: #cfaa09;
  --red: red;

  --light-drawer-background: #ffffff;
  --dark-drawer-background: #272727;

  --light-background: #ffffff;
  --light-text: #000000;
  --light-tile-color: #e1e2e3;
  --dark-backdrop: #242424;
  --dark-text: #ffffff;
  --dark-tile-color: #3a3a3c;
}

.appbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: relative;
  z-index: 1000;
}

.appbar-left-container {
  margin-left: 1rem;
}

.appbar-right-container {
  margin-right: 1rem;
}

.appbar-left-container,
.appbar-right-container {
  margin-top: 0.5rem;
}

.appbar-svg-button {
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

/* Drawer */

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--game-width);
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9999;
}
.drawer-content {
  position: relative;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  font-family: var(--font);
  border-radius: 0.3rem;
  height: 100%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2), 0 0 50px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  animation: drawer-in 0.3s ease-out;
}
.drawer-out {
  animation: drawer-out 0.3s ease-in;
}
.display-none {
  display: none !important;
}
.drawer-title-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1.5rem;
  align-items: center;
  max-height: 300px;
}
.drawer-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

@keyframes drawer-in {
  0% {
    transform: translatex(100%);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes drawer-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Media Queries for Title responsiveness */

@media screen and (max-width: 600px) {
  .appbar-center-container > .title-container {
    font-size: 1.25rem;
  }
  .appbar-center-container > .title-container > .title-left-container > span {
    height: 1rem;
    width: 1rem;
    font-size: 2rem;
  }
  .appbar-center-container > .title-container > .title-right-container > span {
    height: 1rem;
    width: 1rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 320px) {
  .appbar-center-container > .title-container {
    font-size: 1.1rem;
  }
  .appbar-center-container > .title-container > .title-left-container > span {
    height: 0.8rem;
    width: 0.8rem;
    font-size: 1.8rem;
  }
  .appbar-center-container > .title-container > .title-right-container > span {
    height: 0.8rem;
    width: 0.8rem;
    font-size: 1.8rem;
  }
}

/* X-Small */
@media screen and (max-width: 320px) {
  .drawer-content {
    width: 280px !important;
  }
}

/* Small */
@media screen and (max-width: 600px) {
  .drawer-content {
    width: 320px;
  }
}

/* Large */

@media screen and (min-width: 600px) {
  .drawer-content {
    width: 350px;
  }
}
